// src/ServerError.js
import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const ServerError = () => {
  return (
    <div className="server-error">
      <h1>500</h1>
      <p>Sorry, something went wrong on our end.</p>
      <Link to="/">Go back to the wishlist</Link>
    </div>
  );
};

export default ServerError;
