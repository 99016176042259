import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

function Wishlist() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get('/api/wishlist')
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.error('Error fetching wishlist:', error);
      });
  }, []);

  return (
    <div className="container">
      <ul>
        <h1>Ønskeliste for Kjetil</h1>
        {items.map((item) => (
          <li key={item._id} className="wishlist-item">
            <a href={item.purchaseLink} target="_blank" rel="noopener noreferrer">
              <img src={item.imageLink} alt={item.name} />
              <div className="wishlist-item-content">
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <button>Buy Now</button>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Wishlist;
