import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Wishlist from './Wishlist';
import Admin from './Admin';
import NotFound from './NotFound';
import ServerError from './ServerError';
import './App.css';

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Wishlist />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/500" element={<ServerError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
