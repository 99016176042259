import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';

function Admin() {
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [newItem, setNewItem] = useState({
    name: '',
    purchaseLink: '',
    imageLink: '',
    description: '',
  });

  // Fetch items from the backend
  const fetchItems = useCallback(() => {
    axios
      .get('/api/wishlist')
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.error('Error fetching items:', error);
      });
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  // Handle input changes for new and editing items
  const handleInputChange = (e, item = null) => {
    const { name, value } = e.target;

    if (item) {
      setEditingItem({ ...item, [name]: value });
    } else {
      setNewItem({ ...newItem, [name]: value });
    }
  };

  // Add a new item
  const handleAddItem = (e) => {
    e.preventDefault();
    axios
      .post('/api/wishlist', newItem)
      .then((response) => {
        console.log('Item added:', response.data);
        setNewItem({ name: '', purchaseLink: '', imageLink: '', description: '' });
        fetchItems();
      })
      .catch((error) => {
        console.error('Error adding item:', error);
      });
  };

  // Edit an existing item
  const handleEditItem = (e) => {
    e.preventDefault();
    axios
      .put(`/api/wishlist/${editingItem._id}`, editingItem)
      .then((response) => {
        console.log('Item updated:', response.data);
        setEditingItem(null);
        fetchItems();
      })
      .catch((error) => {
        console.error('Error updating item:', error);
      });
  };

  // Delete an item
  const handleDeleteItem = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      axios
        .delete(`/api/wishlist/${id}`)
        .then((response) => {
          console.log('Item deleted:', response.data);
          fetchItems();
        })
        .catch((error) => {
          console.error('Error deleting item:', error);
        });
    }
  };

  return (
    <div className="container">
      <h1>Admin Dashboard</h1>

      {/* Add New Item Form */}
      <h2>Add New Item</h2>
      <form onSubmit={handleAddItem} className="admin-form">
        <input
          type="text"
          name="name"
          placeholder="Item Name"
          value={newItem.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="url"
          name="purchaseLink"
          placeholder="Purchase Link"
          value={newItem.purchaseLink}
          onChange={handleInputChange}
          required
        />
        <input
          type="url"
          name="imageLink"
          placeholder="Image Link"
          value={newItem.imageLink}
          onChange={handleInputChange}
          required
        />
        <textarea
          name="description"
          placeholder="Description"
          value={newItem.description}
          onChange={handleInputChange}
        />
        <button type="submit">Add Item</button>
      </form>

      {/* List of Items */}
      <h2>Manage Items</h2>
      <ul className="admin-list">
        {items.map((item) => (
          <li key={item._id} className="admin-item">
            {editingItem && editingItem._id === item._id ? (
              // Edit Item Form
              <form onSubmit={handleEditItem} className="admin-form">
                <input
                  type="text"
                  name="name"
                  placeholder="Item Name"
                  value={editingItem.name}
                  onChange={(e) => handleInputChange(e, editingItem)}
                  required
                />
                <input
                  type="url"
                  name="purchaseLink"
                  placeholder="Purchase Link"
                  value={editingItem.purchaseLink}
                  onChange={(e) => handleInputChange(e, editingItem)}
                  required
                />
                <input
                  type="url"
                  name="imageLink"
                  placeholder="Image Link"
                  value={editingItem.imageLink}
                  onChange={(e) => handleInputChange(e, editingItem)}
                  required
                />
                <textarea
                  name="description"
                  placeholder="Description"
                  value={editingItem.description}
                  onChange={(e) => handleInputChange(e, editingItem)}
                />
                <button type="submit">Save</button>
                <button type="button" onClick={() => setEditingItem(null)}>
                  Cancel
                </button>
              </form>
            ) : (
              // Display Item with Edit and Delete Options
              <div className="admin-item-content">
                <img src={item.imageLink} alt={item.name} />
                <div>
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <a href={item.purchaseLink} target="_blank" rel="noopener noreferrer">
                    View Item
                  </a>
                  <div className="admin-buttons">
                    <button onClick={() => setEditingItem(item)}>Edit</button>
                    <button onClick={() => handleDeleteItem(item._id)}>Delete</button>
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Admin;
